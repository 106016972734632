import React from 'react';
import './HeroSection.css'; // Import CSS file for hero section styling

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Saturn Risin9</h1>
      </div>
    </section>
  );
};

export default HeroSection;
