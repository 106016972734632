import React from 'react';
import './AboutSection.css'; // Import CSS file for About section styling

const AboutSection = () => {
  return (
    <section className="about-overlay">
      <div className="about-content">
        <p>
          Saturn is a singer, performer, filmmaker, and DJ. They have opened for Mariah Carey, Megan Thee Stallion, and performed at LA Pride, SF Pride Main Stage, Oakland Pride, and Outside Lands Music Festival. They have toured Korea, Canada, Mexico, and the United States. Saturn regularly hosts parties at Soho Houses and recently sold out their first headline concert. Current projects include a film with Red Bull and a Rings Alliance charity for trans and queer artists
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
