import React from 'react';
import './App.css'; // Import CSS file for global styles
import HeroSection from './HeroSection';
import SocialSection from './SocialSection';
import AboutSection from './AboutSection';
import ContactSection from './ContactSection';

const App = () => {
  return (
    <div className="app">
      <HeroSection />
      <SocialSection />
      <AboutSection />
      <ContactSection />
    </div>
  );
};

export default App;
