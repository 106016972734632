import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS
import React from 'react';
import './SocialSection.css'; // Import CSS file for social section styling

const SocialSection = () => {
  return (
    <section className="social-overlay">
      <div className="social-icons">
        <li>
          <a href="https://instagram.com/saturnrisin9" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/saturnrisin9" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://open.spotify.com/artist/4O3kOvWrWSNyBDTHEBOxu6" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-spotify"></i>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UChsccdSl-_COY6iVnKmPHvg" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/saturnisrising" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://saturnrisin9.bandcamp.com/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-bandcamp"></i>
          </a>
        </li>
      </div>
    </section>
  );
};

export default SocialSection;


/*
import React, { useEffect, useState } from 'react';

const SocialSection = () => {
  const [showSocial, setShowSocial] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Adjust the threshold value to control when the social section appears
      if (scrollPosition > 100) {
        setShowSocial(true);
      } else {
        setShowSocial(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="socials" className={`social-section ${showSocial ? 'show' : ''}`}>
      <h2>Connect with Saturn Risin9</h2>
      <ul className="social-icons">
        <li>
          <a href="https://instagram.com/saturnrisin9" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/saturnrisin9" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://open.spotify.com/artist/4O3kOvWrWSNyBDTHEBOxu6" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-spotify"></i>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UChsccdSl-_COY6iVnKmPHvg" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/saturnisrising" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://saturnrisin9.bandcamp.com/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-bandcamp"></i>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default SocialSection;


*/