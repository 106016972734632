import React from 'react';
import './ContactSection.css'; // Import CSS file for Contact section styling

const ContactSection = () => {
  return (
    <section className="contact-overlay">
      <div className="contact-content">
        {/* <h2>Contact Us</h2> */}
        <p>For Business Inqueries or Bookings:</p>
        <p><a href="mailto:info@saturnrisin9.com">management@saturnrisin9.com</a></p>
      </div>
    </section>
  );
};

export default ContactSection;
